<template>
  <div class="shop-footer">
    <div
      class="container"
      v-if="studio && !hideStudioContactInformation"
    >
      <hr v-if="!selectedTemplate.config.hideFooterDivider" class="my-4" />
      <div class="row text-center text-md-start">
        <div
          class="col-sm-12 col-md-4 d-flex justify-content-center justify-content-md-start align-items-start"
        >
          <div>
            <h5 class="mb-3">{{ $t('STUDIO.LABELS.ADDRESS') }}</h5>
            <p class="mb-0">{{ studio.studio_name }}</p>
            <p class="mb-0">{{ studio.address }}</p>
            <p class="mb-0">
              <span v-if="studio.city">
                {{ studio.city }}, {{ studio.state }} {{ studio.zipcode }}</span
              >
            </p>
            <p class="mb-0">{{ studio.country }}</p>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 mt-4 mt-md-0">
          <div class="image-and-tip-container">
            <div class="flex-grow-1">
              <NuxtLink
                class="undecorated-router-link"
                :to="{ name: 'studio-home-page' }"
              >
                <img
                  v-if="studio.logo && studio.logo.storage_url"
                  class="logo-image"
                  :class="{ tip: studio.tip_jar_enabled && !isExpired }"
                  :src="studio.logo.storage_url"
                />
              </NuxtLink>
            </div>
            <div
              class="ms-md-1 mt-md-4 my-4"
              v-if="studio.tip_jar_enabled && !isExpired"
            >
              <HMTipButton
                :class="{
                  'no-border-radius': !selectedTemplate.config.borderRadius,
                }"
                :color="selectedTemplate.config.buttonColor"
              ></HMTipButton>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 col-md-4 d-flex justify-content-center justify-content-md-end align-items-start"
        >
          <div>
            <h5 class="mb-3">{{ $t('STUDIO.TEXT.STAY_IN_TOUCH') }}</h5>
            <p v-if="studio.email">
              <i class="fa fa-envelope-o c-coral me-2"></i>
              <a
                :href="`mailto:${studio.email}`"
                target="_blank"
                class="c-charcoal"
                >{{ studio.email }}</a
              >
            </p>
            <p v-if="studio.phone">
              <i class="fa fa-phone c-coral me-2"></i>
              <a :href="`tel:${studio.phone}`" class="c-charcoal">{{
                studio.phone
              }}</a>
            </p>
            <StudioSocial :studio="studio" />
          </div>
        </div>
      </div>
      <div class="row py-4">
        <div class="col-12 py-2"></div>
      </div>
    </div>
    <footer class="footer-fixed">
      <div class="container">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <HMMadeBy class="flex-grow-1" />
          <div
            class="legal d-flex flex-row align-items-center justify-content-end flex-nowrap"
          >
            <a
              class="px-2 px-lg-4"
              :href="config.termsOfUseURL"
              target="_blank"
            >
              Terms of Use
            </a>
            <a :href="config.privacyPolicyURL" target="_blank">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useStudioStore, useUserStore } from '@/stores';
import StudioSocial from '@/components/navigation/StudioSocial.vue';
import { getConfig } from '@/utils/configuration';
import HMTipButton from '@/components/common/HMTipButton.vue';

export default {
  name: 'StudioFooter',
  components: {
    HMTipButton,
    StudioSocial,
  },
  setup() {
    const url = useRequestURL();

    return {
      config: getConfig(url)
    }
  },
  props: {
    forceHideStudioContactInformation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
      selectedTemplate: 'selectedTemplate',
      isExpired: 'isExpired',
    }),
    ...mapState(useUserStore, {
      teacher: 'teacher',
      teacherStudioUrl: 'teacherStudioUrl',
      studentCommunityProfileFirstName: 'studentCommunityProfileFirstName',
    }),
    hideStudioContactInformation() {
      return this.forceHideStudioContactInformation || this.$route.meta?.hideStudioContactInformation;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: $shop-footer-height;
  line-height: $shop-footer-height;
  background-color: $white;
  z-index: $zindex-fixed;
  font-size: 10px;

  @include media-breakpoint-down(md) {
    font-size: 10px;
  }
}

.logo-image {
  max-width: 100%;
  max-height: 150px;
  &.tip {
    max-height: 75px;
  }
}

.image-and-tip-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.legal,
.social-icons {
  a {
    color: $dark-gray;
  }
}
</style>
